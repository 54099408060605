import useClickOutside from "@/hooks/ClickOutsideHook";
import { useUserSession } from "@/UserSessionProvider";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { LuChevronDown, LuUser } from "react-icons/lu";

const ProfileButton = () => {
  const { user, setUser } = useUserSession();
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  useClickOutside(handleClickOutside);

  const logout = () => {
    setUser(null);
    router.push("/sign-out");
  };

  const goToProfilePage = () => {
    router.push("/agent-admin/profile");
  };

  return (
    <>
      {user ? (
        <div className="relative " onClick={() => setIsOpen(!isOpen)}>
          <div className="flex items-center cursor-pointer ">
            <div className="flex items-center justify-center w-8 h-8 bg-[#1ED760] rounded-full cursor-pointer">
              <LuUser className="text-white" />
            </div>
            <div className="ml-2 w-[85px]">
              <div className="font-semibold text-white overflow-hidden whitespace-nowrap text-ellipsis">
                {user?.name}
              </div>
            </div>
            <LuChevronDown className="text-white" />
          </div>

          {isOpen && (
            <div>
              <div className="absolute top-10 right-0 w-48 bg-white rounded shadow-lg">
                <div
                  className="p-2 clickable"
                  onClick={() => {
                    goToProfilePage();
                  }}
                >
                  Profile
                </div>

                <div
                  className="p-2 clickable cursor-pointer"
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex">
          <div className="ml-2">
            <a href={"/sign-in"} className="font-semibold text-white">
              Login/Register
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileButton;
